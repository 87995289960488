<template>
  <div class="content">
    <a class="button" href="/login"
      ><i class="fa fa-sign-in fa-fw"></i>LOGIN
    </a>
  </div>
</template>

<style scoped>
.content {
  width: 200px;
  max-width: 500px;
  margin: auto;
  background: rgb(255, 208, 208);
  padding: 10px;
}

a.button {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  text-decoration: none;
  width: 200px;
  background-color: rgb(105, 166, 209);
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  cursor: pointer;
  font-size: 18px;
  height: 50px;
  margin-top: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

a.button:hover,
a.button:active {
  background-color: #06b;
  background-color: #06b;
}
</style>
