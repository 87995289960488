<template>
  <div class="w3-container" style="padding: 128px 16px" id="team">
    <div class="w3-row-padding" style="margin-top: 10px">
      <div style="width: 100%" class="w3-col l3 m6 w3-margin-bottom">
        <div class="w3-card">
          <div class="w3-container">
            <h3>Pääkäyttäjän asetukset</h3>

            <p class="strong">Käyttäjät:</p>

            <p v-for="user in userAccounts" :key="user.aadUserId">
              {{ user.aadUserId }}: {{ user.userFullName }}
            </p>

            <p><base-button>Tallenna</base-button></p>
            <p><base-button @click="purgeAllEntries">Purge DB</base-button></p>
            <p><base-button @click="populateDb">Populate DB</base-button></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from './UI/BaseButton.vue';

export default {
  components: { BaseButton },
  emits: ['getEntriesFromDb'],
  props: ['userAccounts'],

  data() {
    return {
      userFullName: null,
    };
  },

  methods: {
    populateDb() {
      const initialEntries = [
        {
          pvm: 1609459200000,
          rata: 'Loimaan ampumarata (Loimaan Laukojat), Loimaa',
          ase: 'Pistooli 9x19mm, itselataava kertatuli (TT3)',
          laukaukset: 50,
          muuta: 'Test Test 12345',
          aadUserId: '56a0956861c2a06c6c86d9375a53e285',
        },
        {
          pvm: 1643760000000,
          rata: 'Loimaan ampumarata (Loimaan Laukojat), Loimaa',
          ase: 'Pistooli 9x19mm, itselataava kertatuli (TT3)',
          laukaukset: 100,
          muuta: 'Antin kanssa treenaamassa',
          aadUserId: '56a0956861c2a06c6c86d9375a53e285',
        },
        {
          pvm: 1677801600000,
          rata: 'Raasin ampumarata (Puolustusvoimat), Pöytyä',
          ase: 'Haulikko 12kal, itselataava kertatuli (TT3)',
          laukaukset: 120,
          aadUserId: '56a0956861c2a06c6c86d9375a53e285',
        },
        {
          pvm: 1712188800000,
          rata: 'Raasin ampumarata (Puolustusvoimat), Pöytyä',
          ase: 'Haulikko 12kal, itselataava kertatuli (TT3)',
          laukaukset: 120,
          aadUserId: '56a0956861c2a06c6c86d9375a53e285',
        },
        {
          pvm: 1746403200000,
          rata: 'Huovinrinteen ampumarata (Puolustusvoimat), Säkylä',
          ase: 'Haulikko 12kal, itselataava kertatuli (TT3)',
          laukaukset: 50,
          aadUserId: '56a0956861c2a06c6c86d9375a53e285',
        },
        {
          pvm: 1780704000000,
          rata: 'Huovinrinteen ampumarata (Puolustusvoimat), Säkylä',
          ase: 'Kivääri .223, itselataava kertatuli (TT3)',
          laukaukset: 100,
          muuta: 'Antin kanssa treenaamassa',
          aadUserId: '56a0956861c2a06c6c86d9375a53e285',
        },
        {
          pvm: 1814918400000,
          rata: 'Hevonpään ampumarata (SaSa), Halikko',
          ase: 'Kivääri .223, itselataava kertatuli (TT3)',
          laukaukset: 120,
          aadUserId: '56a0956861c2a06c6c86d9375a53e285',
        },
        {
          pvm: 1849305600000,
          rata: 'Hevonpään ampumarata (SaSa), Halikko',
          ase: 'Kivääri .223, itselataava kertatuli (TT3)',
          laukaukset: 120,
          aadUserId: '56a0956861c2a06c6c86d9375a53e285',
        },
                {
          pvm: 1609459200000,
          rata: 'Loimaan ampumarata (Loimaan Laukojat), Loimaa',
          ase: 'Pistooli 9x19mm, itselataava kertatuli (TT3)',
          laukaukset: 50,
          muuta: 'Test Test 12345',
          aadUserId: 'd91183feaf6d4afc8cbd27f90a079220',
        },
        {
          pvm: 1643760000000,
          rata: 'Loimaan ampumarata (Loimaan Laukojat), Loimaa',
          ase: 'Pistooli 9x19mm, itselataava kertatuli (TT3)',
          laukaukset: 100,
          muuta: 'Antin kanssa treenaamassa',
          aadUserId: 'd91183feaf6d4afc8cbd27f90a079220',
        },
        {
          pvm: 1677801600000,
          rata: 'Raasin ampumarata (Puolustusvoimat), Pöytyä',
          ase: 'Haulikko 12kal, itselataava kertatuli (TT3)',
          laukaukset: 120,
          aadUserId: 'd91183feaf6d4afc8cbd27f90a079220',
        },
        {
          pvm: 1712188800000,
          rata: 'Raasin ampumarata (Puolustusvoimat), Pöytyä',
          ase: 'Haulikko 12kal, itselataava kertatuli (TT3)',
          laukaukset: 120,
          aadUserId: 'd91183feaf6d4afc8cbd27f90a079220',
        },
        {
          pvm: 1746403200000,
          rata: 'Huovinrinteen ampumarata (Puolustusvoimat), Säkylä',
          ase: 'Haulikko 12kal, itselataava kertatuli (TT3)',
          laukaukset: 50,
                    aadUserId: 'd91183feaf6d4afc8cbd27f90a079220',
        },
        {
          pvm: 1780704000000,
          rata: 'Huovinrinteen ampumarata (Puolustusvoimat), Säkylä',
          ase: 'Kivääri .223, itselataava kertatuli (TT3)',
          laukaukset: 100,
          muuta: 'Antin kanssa treenaamassa',
          aadUserId: 'd91183feaf6d4afc8cbd27f90a079220',
        },
        {
          pvm: 1814918400000,
          rata: 'Hevonpään ampumarata (SaSa), Halikko',
          ase: 'Kivääri .223, itselataava kertatuli (TT3)',
          laukaukset: 120,
                    aadUserId: 'd91183feaf6d4afc8cbd27f90a079220',
        },
        {
          pvm: 1849305600000,
          rata: 'Hevonpään ampumarata (SaSa), Halikko',
          ase: 'Kivääri .223, itselataava kertatuli (TT3)',
          laukaukset: 120,
          aadUserId: 'd91183feaf6d4afc8cbd27f90a079220',
        },
      ];
      console.log(initialEntries);
      initialEntries.forEach((entry) => {
        console.log(entry);
        fetch('/api/entries', {
          method: 'POST', // POST to create new item
          body: JSON.stringify(entry), // Add task to body
          headers: {
            'Content-Type': 'application/json', // Set return type to JSON
          },
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              console.log('Response not OK');
            }
          })
          .then((data) => {
            console.log(data);
          })
          .catch(() => {
            console.log('Caught an error!');
          });
      });
    },
    purgeAllEntries() {
      fetch('api/entries?option=delete-all', {
        method: 'DELETE', // DELETE METHOD
      })
        .then((response) => {
          console.log(response);
          if (response.ok) {
            console.log('OK. All deleted!');
            return;
          } else {
            console.log('Response not OK');
            return;
          }
        })
        .catch(() => {
          console.log('Caught an error!');
        });
    },
  },
};
</script>

<style scoped>
.strong {
  font-weight: bold;
}
</style>
