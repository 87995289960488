<template>
  <!-- Navbar (sit on top) -->
  <div class="w3-top">
    <div class="w3-bar w3-white w3-card" id="myNavbar">
      <a
        @click="$emit('newActiveTab', 'admin-settings')"
        class="w3-bar-item w3-button w3-wide"
        >AMPUMAPÄIVÄKIRJA -
        <span style="color: red"><strong>ADMIN</strong></span></a
      >
      <!-- Right-sided navbar links -->
      <div class="w3-right w3-hide-small">
        <!--         <span :class="{ activelink: activeTab === 'insert-entry' }">
          <a
            @click="$emit('newActiveTab', 'insert-entry')"
            class="w3-bar-item w3-button"
            ><i class="fa fa-pencil"></i> LISÄÄ</a
          >
        </span>
        -->
        <span :class="{ activelink: activeTab === 'log-entries' }">
          <a
            @click="$emit('newActiveTab', 'log-entries')"
            class="w3-bar-item w3-button"
            ><i class="fa fa-book"></i> PÄIVÄKIRJA</a
          ></span
        >
        <!--
        <span :class="{ activelink: activeTab === 'print-entries' }">
          <a
            @click="$emit('newActiveTab', 'print-log-entries')"
            class="w3-bar-item w3-button"
            ><i class="fa fa-print"></i> TULOSTA</a
          ></span
        >
        <span :class="{ activelink: activeTab === 'user-settings' }">
          <a
            @click="$emit('newActiveTab', 'user-settings')"
            class="w3-bar-item w3-button"
            ><i class="fa fa-user"></i> KÄYTTÄJÄ</a
          ></span
        > -->
        <span :class="{ activelink: activeTab === 'admin-settings' }">
          <a
            @click="$emit('newActiveTab', 'admin-settings')"
            class="w3-bar-item w3-button"
            ><i class="fa fa-cog"></i> PÄÄKÄYTTÄJÄ</a
          ></span
        >
        <a href="/logout" class="w3-bar-item w3-button"
          ><i class="fa fa-sign-out"></i> LOPETA</a
        >
      </div>
      <!-- Hide right-floated links on small screens and replace them with a menu icon -->

      <a
        href="javascript:void(0)"
        class="w3-bar-item w3-button w3-right w3-hide-large w3-hide-medium"
        @click="w3_open()"
      >
        <i class="fa fa-bars fa-2x"></i>
      </a>
    </div>
  </div>

  <!-- Sidebar on small screens when clicking the menu icon -->
  <nav
    class="
      w3-sidebar
      w3-bar-block
      w3-white
      w3-card
      w3-animate-left
      w3-hide-medium
      w3-hide-large
    "
    style="display: none"
    id="mySidebar"
  >
    <a
      href="javascript:void(0)"
      @click="w3_close()"
      class="w3-bar-item w3-button w3-large w3-padding-16"
      >SULJE ×</a
    >
    <!--     <span :class="{ activelink: activeTab === 'insert-entry' }">
      <a
        @click="
          w3_close();
          $emit('newActiveTab', 'insert-entry');
        "
        class="w3-bar-item w3-button"
        ><i class="fa fa-pencil"></i> LISÄÄ</a
      >
      
    </span>
    -->
    <span :class="{ activelink: activeTab === 'log-entries' }">
      <a
        @click="
          w3_close();
          $emit('newActiveTab', 'log-entries');
        "
        class="w3-bar-item w3-button"
        ><i class="fa fa-book"></i> KAIKKI PÄIVÄKIRJAT</a
      ></span
    >
    <!--
    <span :class="{ activelink: activeTab === 'print-log-entries' }">
      <a
        @click="
          w3_close();
          $emit('newActiveTab', 'print-entries');
        "
        class="w3-bar-item w3-button"
        ><i class="fa fa-print"></i> TULOSTA</a
      ></span
    >
    <span :class="{ activelink: activeTab === 'user-settings' }">
      <a
        @click="
          w3_close();
          $emit('newActiveTab', 'user-settings');
        "
        class="w3-bar-item w3-button"
        ><i class="fa fa-user"></i> KÄYTTÄJÄ</a
      ></span
    > -->
    <span :class="{ activelink: activeTab === 'admin-settings' }">
      <a
        @click="
          w3_close();
          $emit('newActiveTab', 'admin-settings');
        "
        class="w3-bar-item w3-button"
        ><i class="fa fa-cog"></i> PÄÄKÄYTTÄJÄ</a
      ></span
    >
    <a href="/logout" class="w3-bar-item w3-button"
      ><i class="fa fa-sign-out"></i>LOPETA
    </a>
  </nav>
</template>

<script>
export default {
  emits: ['newActiveTab'],
  props: ['activeTab', 'aadUserDetails', 'aadUserIsAdmin'],
  components: {
    //BaseButton,
    //BaseCard,
  },
  methods: {
    // Toggle between showing and hiding the sidebar when clicking the menu icon

    w3_open() {
      const mySidebar = document.getElementById('mySidebar');
      if (mySidebar.style.display === 'block') {
        mySidebar.style.display = 'none';
      } else {
        mySidebar.style.display = 'block';
      }
    },

    // Close the sidebar with the close button
    w3_close() {
      const mySidebar = document.getElementById('mySidebar');
      mySidebar.style.display = 'none';
    },
  },
};
</script>

<style scoped>
.activelink {
  background-color: rgb(105, 166, 209);
}
</style>
